import { PermissionFeatureKeyType } from 'admin/types/permission';
import {
  AUTH_CODE_TYPES,
  CORPORATION_USER_POOL_PERMISSION_TENANT,
  CORPORATION_USER_POOL_PERMISSION_USER_POOL,
  LOGIN_AUTH_MODE,
  PERMISSIONS,
  USER_POOL_TYPES,
} from 'define';
// TODO
// A [--- auth or 本人取得 or IDでユーザー取得 ---] getUser関数
// user/login/ ==> User
// user/ ==> User (セッションIDがバックに渡るのでそれを頼りに本人情報取得)
// user/{ユーザーID}/ ==> User (他人が取得はできます)

// B [--- テナントを指定してユーザーを取得 ---]
// user/tenant/テナントID/ ==> UserManage[] getTenantUserList関数
// user/tenant/テナントID/{ユーザーID}/ ==> UserManage getTenantUser関数

// C [--- グループを指定してユーザーを取得 ---]
// user/group/グループID/ ==> UserManage[] getGroupUserList関数
// user/group/グループID/{ユーザーID}/ ==> UserManage getGroupUser関数

// ログインした時のユーザー情報の型
export type User = {
  id: string;
  name: string;
  email: string;
  accessToken?: string;
  refreshToken?: string;
  // 配列(Aパターン), オブジェクト(Bパターン, Cパターン)
  tenant: UserTenant[] | UserTenant;
  // tenantId: string;
  // tenantName: string;
  // テナント権限
  // permission: typeof PERMISSIONS[number];
  // デフォルトグループロール
  // role: typeof PERMISSIONS[number];

  // 所属している法人のリスト
  // TODO: 将来的に必要となる型定義
  corporations: Corporations;
};

// ユーザーが所属するテナントの情報の型
export type UserTenant = {
  // テナントID
  id: string;
  // テナント名
  name: string;
  // テナント権限
  permission: typeof PERMISSIONS[number];
  // グループ
  groups: Group[];
  // 利用できる機能ID
  features: number[];
  // ホスティング機能を利用できるか
  hostingDomain?: string | null;
  // 設問の一部を非表示にするフラグ
  hasUniikey1FormPreset?: boolean;
  // 設問タイプ
  authType?: number;
  // ログインURL
  loginUrl?: string | null;
};

export type Group = {
  id: string;
  name?: string;
};

export type CorporationUserPoolPermissionType = 'userpool' | 'tenant';

export type CorporationUserPool = {
  userPoolId: string;
  userPoolType: number; // TODO: define等で定数管理したほうがよさそう
  authenticationType: number; // TODO: define等で定数管理したほうがよさそう
  permission: CorporationUserPoolPermissionType[];
};

export type CorporationUserPools = CorporationUserPool[];

export type Corporation = {
  id: string;
  name: string;
  permission: PermissionFeatureKeyType[];
  userPoolGroupId: string | null;
  userPools: CorporationUserPools;
};

export type Corporations = Corporation[];

/**
 * アカウント登録画面認証チェックレスポンス Type
 */
export type VerifyUser = {
  invitationCode: string;
  authTypeCode: typeof AUTH_CODE_TYPES[number];
  message: string;
  email: string;
};

/**
 * アカウント(テナントユーザー)登録成功時レスポンス Type
 */
export type CreatedTenantUser = {
  id: string;
  email: string;
  name: string;
  groups: string[];
  tenant: string;
  permission: typeof PERMISSIONS[number];
};

/**
 * アカウント(法人ユーザー)登録成功時レスポンス Type
 */
export type CreatedCorporationUser = {
  id: string;
  email: string;
  name: string;
  corporation: string;
  permission: typeof PERMISSIONS[number][];
};

/**
 * ユーザー招待からの登録Form用 Type
 */
export type InvitationUser = {
  name: string;
  password: string;
  passwordConfirm: string;
};

/**
 * ユーザー招待Form用 Type
 */
export type RegistInviteUserForm = {
  emailTo: string;
  tenant: string;
  permission: typeof PERMISSIONS[number];
  groups: { id: string; name?: string }[] | string[];
  corporation?: string;
};

/**
 * ユーザー招待登録用 Type
 */
export type RegistInviteUser = {
  user: string;
  emailTo: string;
  emailFrom: string;
  invitationCode: string;
  tenant: string;
  permission: typeof PERMISSIONS[number];
  groups: string[];
  expiry: string;
};

/**
 * ユーザーテナント設定変更Form用 Type
 */
export type UserTenantEditForm = {
  tenantId: string;
  userId: string;
  email: string;
  permission: typeof PERMISSIONS[number];
  groups: { id: string; name?: string }[] | string[];
};

/**
 * ユーザー管理 削除用 type
 */
export type UserDeleteState = {
  id: string;
  name: string;
  email: string;
  permission: typeof PERMISSIONS[number];
  state: boolean;
};

/**
 * ユーザー管理 削除用 type
 */
export type UserTenantDelete = {
  users: string[];
  tenantId: string;
};

/**
 * ユーザー管理 削除アラート用 type
 */
export type UserTenantWarningDelete = {
  warning?: string;
};

/**
 * パスワードリセット用 type
 */
export type PasswordReset = {
  user: string;
};

export type PasswordChange = {
  id: string;
  email: string;
  name: string;
};

/**
 * パスワード再設定用 type
 */
export type PasswordResetForm = {
  uuid: string;
  password: string;
  passwordConfirm?: string;
};

export type PasswordResetFormForget = {
  email: string;
};

export type UserSettingForm = {
  name: string;
  email: string;
};

export type UserEditPasswordForm = {
  password: string;
  passwordConfirm: string;
};

export type LoginFormType = {
  email: string;
  password: string;
};

export type LoginAuthType = {
  email: string;
  authType: {
    name: string;
    code: LoginAuthTypeValue;
  };
  userPoolId: string;
};

export type CertificationLoginFormType = {
  email: string;
  password: string;
  resolve: (value: LoginAuthType) => void;
};
export type SamlLoginAuthType = {
  email: string;
  userPoolId: string;
  resolve: (value: SamlLoginResponseType) => void;
};
export type SamlLoginResponseType = {
  redirectUrl: string;
};

export type LoginAuthTypeValue = typeof AUTH_CODE_TYPES[number];
export type LoginAuthModeValue = typeof LOGIN_AUTH_MODE[number];
export type UserPoolTypeValue = typeof USER_POOL_TYPES[number];

/**
 * それぞれのアクセス権
 *
 * hasAuthTenant
 *
 * → テナント（通常の管理画面）に対するアクセス権
 *
 * hasAuthCorporation
 *
 * → 法人（組織管理画面）に対するアクセス権
 *
 * hasAuthBoth
 *
 * → テナント、法人両方のアクセス権
 */
export type AuthStatusType = {
  hasAuthTenant: boolean;
  hasAuthCorporation: boolean;
  hasAuthBoth: boolean;
};

const isCorporationUserPool = (data: unknown): data is CorporationUserPool => {
  const up = data as CorporationUserPool;

  return (
    typeof up?.userPoolId === 'string' &&
    typeof up?.userPoolType === 'number' &&
    typeof up?.authenticationType === 'number' &&
    Array.isArray(up?.permission) &&
    !up?.permission.some(
      (d) =>
        !(
          d === CORPORATION_USER_POOL_PERMISSION_USER_POOL ||
          d === CORPORATION_USER_POOL_PERMISSION_TENANT
        ),
    )
  );
};

const isCorporationUserPools = (
  datas: unknown[],
): datas is CorporationUserPools =>
  !datas.some((d) => !isCorporationUserPool(d));

export const isCorporation = (data: unknown): data is Corporation => {
  const co = data as Corporation;

  return (
    typeof co?.id === 'string' &&
    typeof co?.name === 'string' &&
    Array.isArray(co?.permission) &&
    (co?.userPoolGroupId === null
      ? true
      : typeof co?.userPoolGroupId === 'string') &&
    isCorporationUserPools(co?.userPools)
  );
};

export const isUserTenant = (data: unknown): data is UserTenant => {
  const ut = data as UserTenant;

  const featuresValid =
    ut?.features === undefined ||
    ut?.features.length === 0 ||
    ut?.features.some((d) => typeof d === 'number');

  return (
    typeof ut?.id === 'string' &&
    typeof ut?.name === 'string' &&
    PERMISSIONS.indexOf(ut?.permission) >= 0 &&
    (ut?.groups === undefined
      ? true
      : Array.isArray(ut?.groups) &&
        (ut?.groups.length === 0 ||
          ut?.groups.every((v: Group) => {
            const grp = v;

            return typeof grp?.id === 'string' && typeof grp?.name === 'string';
          }) ||
          ut?.groups.every((v) => typeof v === 'string'))) &&
    featuresValid &&
    (ut?.hostingDomain === undefined
      ? true
      : typeof ut?.hostingDomain === 'string' || ut?.hostingDomain === null) &&
    (ut?.hasUniikey1FormPreset === undefined
      ? true
      : typeof ut?.hasUniikey1FormPreset === 'boolean') &&
    (ut?.authType === undefined ? true : typeof ut?.authType === 'number') &&
    (ut?.loginUrl === undefined
      ? true
      : typeof ut?.loginUrl === 'string' || ut?.loginUrl === null)
  );
};

export const isUserTenants = (datas: unknown[]): datas is UserTenant[] =>
  !datas.some((d) => !isUserTenant(d));

export const isUser = (data: unknown): data is User => {
  const u = data as User;

  return (
    typeof u?.id === 'string' &&
    typeof u?.email === 'string' &&
    typeof u?.name === 'string' &&
    (u?.accessToken !== undefined
      ? typeof u?.accessToken === 'string'
      : true) &&
    (u?.refreshToken !== undefined
      ? typeof u?.refreshToken === 'string'
      : true) &&
    (Array.isArray(u?.tenant)
      ? isUserTenants(u?.tenant as unknown[])
      : isUserTenant(u?.tenant)) &&
    (u?.corporations === undefined
      ? true
      : !u?.corporations.some((d) => !isCorporation(d)))
  );
};

export const isUsers = (datas: unknown[]): datas is User[] =>
  !datas.some((d) => !isUser(d));

/**
 * アカウント登録成功時レスポンス Type 型ガード
 * @param data VerifyUser
 * @returns boolean
 */
export const isVerifyUser = (data: unknown): data is VerifyUser => {
  const vu = data as VerifyUser;

  return (
    typeof vu.invitationCode === 'string' &&
    typeof vu.authTypeCode === 'number' &&
    typeof vu.message === 'string' &&
    typeof vu.email === 'string'
  );
};

/**
 * ユーザー招待登録成功時レスポンス Type 型ガード
 * @param data RegistInviteUser
 * @returns boolean
 */
export const isRegistInviteUser = (data: unknown): data is RegistInviteUser => {
  const riu = data as RegistInviteUser;

  return (
    typeof riu?.user === 'string' &&
    typeof riu?.emailTo === 'string' &&
    typeof riu?.emailFrom === 'string' &&
    typeof riu?.invitationCode === 'string' &&
    typeof riu?.tenant === 'string' &&
    typeof riu?.expiry === 'string' &&
    PERMISSIONS.indexOf(riu?.permission) >= 0 &&
    Array.isArray(riu?.groups) &&
    (riu?.groups.length === 0 ||
      riu?.groups.every((v) => typeof v === 'string'))
  );
};

/**
 * グループtype 型ガード
 */
export const isGroup = (data: unknown): data is Group => {
  const gp = data as Group;

  return typeof gp.id === 'string';
};

export const isGroups = (datas: unknown[]): datas is Group[] =>
  !datas.some((d) => !isGroup(d));

/**
 * パスワード変更type 型ガード
 */
export const isPasswordChange = (data: unknown): data is PasswordChange => {
  const pc = data as PasswordChange;

  return (
    typeof pc.id === 'string' &&
    typeof pc.email === 'string' &&
    typeof pc.name === 'string'
  );
};

/**
 * 認証タイプ判定 型ガード
 */
export const isLoginAuthType = (data: unknown): data is LoginAuthType => {
  const lat = data as LoginAuthType;

  return (
    typeof lat.email === 'string' &&
    typeof lat.authType.name === 'string' &&
    typeof lat.authType.code === 'number' &&
    (lat.userPoolId !== null ? typeof lat.userPoolId === 'string' : true)
  );
};

/**
 * Samlログイン認証レスポンス
 */
export const isSamlLoginResponseType = (
  data: unknown,
): data is SamlLoginResponseType => {
  const sl = data as SamlLoginResponseType;

  return typeof sl.redirectUrl === 'string';
};

/**
 * テナントユーザー作成後レスポンス型ガード
 */
export const isCreatedTenantUser = (
  data: unknown,
): data is CreatedTenantUser => {
  const ctu = data as CreatedTenantUser;

  const groupsValid =
    ctu?.groups === undefined ||
    ctu?.groups.length === 0 ||
    ctu?.groups.every((d) => typeof d === 'string');

  return (
    typeof ctu.id === 'string' &&
    typeof ctu.email === 'string' &&
    typeof ctu.name === 'string' &&
    groupsValid &&
    typeof ctu.tenant === 'string' &&
    PERMISSIONS.indexOf(ctu?.permission) >= 0
  );
};

/**
 * 法人ユーザー作成後レスポンス型ガード
 */
export const isCreatedCorporationUser = (
  data: unknown,
): data is CreatedCorporationUser => {
  const ccu = data as CreatedCorporationUser;

  const coporationUserPermissionGuard = (
    permission: CreatedCorporationUser['permission'],
  ) =>
    permission.length > 0
      ? permission.some((d) => typeof d === 'number')
      : true;

  return (
    typeof ccu.id === 'string' &&
    typeof ccu.email === 'string' &&
    typeof ccu.name === 'string' &&
    typeof ccu.corporation === 'string' &&
    (ccu?.permission === undefined
      ? true
      : coporationUserPermissionGuard(ccu.permission))
  );
};
